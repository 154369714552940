import { render, staticRenderFns } from "./df-page-new.vue?vue&type=template&id=c8219f5c&"
import script from "./df-page-new.ts?vue&type=script&lang=js&"
export * from "./df-page-new.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
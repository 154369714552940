import { Component, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import moment from "moment";
import DfStore from "@/vue/domain/store/df-store";
import carousel from "vue-owl-carousel2";
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfSectionAttachments: () => Utils.externalComponent2("df-section-attachments"),
    carousel,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPageNewComponent extends Vue {
  private selectedCarouselThumb = 0;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get news(): DfContent {
    return this.newsList ? this.newsList.find((news: DfContent) => news.alias === this.$route.params.newsAlias) : null;
  }

  get newsCoverImage(): string {
    return Utils.getPropertyImageUrl(this.news, Utils.PROPERTY_COVER, null);
  }

  get newsPreviewImage(): string {
    return Utils.getPropertyImageUrl(this.news, Utils.PROPERTY_PREVIEW, null);
  }

  get newsPreview(): string {
    return this.newsCoverImage ? this.newsCoverImage : this.newsPreviewImage;
  }

  get newsPreviewStyle(): string {
    const style: any = {};
    const newsPreviewStyle: string = this.newsPreview;

    if (newsPreviewStyle) {
      style.backgroundImage = `url('${newsPreviewStyle}')`;
    }

    return style;
  }

  get newsTitle(): string {
    return Utils.getPropertyValue(this.news, Utils.PROPERTY_TITLE, "STRING");
  }

  get newsText(): string {
    return Utils.getPropertyValue(this.news, Utils.PROPERTY_TEXT, "TEXT");
  }

  get newsDate(): string {
    return Utils.getPropertyValue(this.news, Utils.PROPERTY_DATE, "DATE");
  }

  get newsAttachments(): Array<any> {
    return Utils.getPropertyValues(this.news, Utils.PROPERTY_ATTACHMENTS, "FILE");
  }

  get medias(): Array<any> {
    return this.newsAttachments.filter((attachment) => attachment.image || attachment.extension === "mp4");
  }

  get files(): Array<any> {
    return this.newsAttachments.filter((attachment) => !(attachment.image || attachment.extension === "mp4"));
  }

  get newsList(): Array<DfContent> {
    const storeNews: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "NWS");
    const brandNews: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "NWS");
    const news: Array<DfContent> = [...storeNews, ...brandNews].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));
    return news;
  }

  @Watch("news", { immediate: true })
  newsChange() {
    document.title = this.newsTitle ? `${this.newsTitle} | ${Utils.APP_NAME}` : "";
    if (this.$route.params.newsAlias && this.newsList.length && !this.news) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  private previewUrl(image: any): string {
    return `${Utils.getEndpointContextPath()}/files/${image.uniqueId}/${image.basename}.${image.extension}`;
  }

  private carouselChange(event: any) {
    this.selectedCarouselThumb = event.item.index % event.item.count;
    Array.from(this.$el.getElementsByTagName("video")).forEach(function (video: any) {
      if (!video.paused) {
        video.pause();
      }
    });
  }
}
